<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-12">
      <div class="card bg-secondary shadow border-0">

        <div class="card-body px-lg-5 py-lg-5">
          <div class="row  mb-5">
            <div class="col-12">
              <!-- <p>Texto aqui</p> -->
              <div class="text-center">
                <a href="/normas_congresso_2023.pdf" target="_blank">
                  <button class="btn btn-primary">Confira as normas do
                    congresso 2023</button>
                </a>
              </div>
              <hr>


            </div>
          </div>
          <form role="form" @submit.prevent="submitFile()">

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Nome" required input-classes="form-control-alternative" placeholder="Nome"
                  v-model="user.name" :errors="errors.name">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input label="CPF" required input-classes="form-control-alternative" placeholder="000.000.000-00"
                  v-model="user.cpf" :errors="errors.cpf" v-mask="'###.###.###-##'">
                </base-input>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <base-input label="Nome do Projeto" required input-classes="form-control-alternative"
                  placeholder="Nome do Projeto" v-model="user.projeto" :errors="errors.projeto">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <base-input label="Descrição do Projeto" required input-classes="form-control-alternative"
                  placeholder="Descrição do Projeto" v-model="user.projeto_descricao" :errors="errors.projeto_descricao">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <base-input type="file" @change="setFile($event)" label="Arquivo do Projeto" nullable
                  input-classes="form-control-alternative" placeholder="Complemento" v-model="user.arquivo"
                  :errors="errors.arquivo">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <base-input :rules="{ required: { allowFalse: false } }" name="Privacy" Policy>
                  <base-checkbox v-model="boolean">
                    <span class="text-muted">Eu concordo com os
                      <a href="#!">Termos e condições</a></span>
                  </base-checkbox>
                </base-input>
              </div>
            </div>
            <div class="text-center">
              <base-button type="primary" native-type="submit" :disabled="loading || success" class="mb-4">Enviar
                <i class="fa fa-spinner fa-spin" v-if="loading"></i></base-button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import CadastroService from "../../services/CadastroPublic";
import { helper } from "../../helper.js";
import axios from "axios";

export default {
  name: "register",
  components: {
    ValidationError,
    Password
  },
  mixins: [formMixin],
  data() {
    return {
      boolean: false,
      arquivo: null,
      user: {
        name: null,
        cpf: null,
        projeto: null,
        projeto_descricao: null,
        arquivo: null,
        seguro_saude: null
      },
      errors: {},

      loading: false,
      success: false,
    };
  },
  methods: {
    verify(event) {
      console.log(event);
    },
    itemValor(item) {
      let isAssociado = this.checkAssociado(item);

      if (item.valores.length > 0) {
        let result = 0;
        item.valores.every(valor => {
          if (valor.categorias.length == 0) {
            if (isAssociado) {
              result = valor.valor_associado;
              return false;
            }
            else {
              result = valor.valor_normal;
              return false;
            }
          }

          if (this.checkValorCategoria(valor)) {
            if (isAssociado) {
              result = valor.valor_associado;
              return false;
            }
            else {
              result = valor.valor_normal;
              return false;
            }
          }
          return true;
        });

        return result;

      } else return 0;
    },
    setCarrinho() {
      let cart = [];
      if (this.user.tipo_cadastro == 'congresso') {
        cart.push(this.congresso);
      }
      else if (this.user.tipo_cadastro == 'congresso_curso') {
        cart.push(this.congresso);
        this.user.cursos_selecteds.forEach(item => {
          cart.push(item)
        })
      }
      else {
        this.user.cursos_selecteds.forEach(item => {
          cart.push(item)
        })
      }
      this.carrinho = cart;
    },
    checkAssociado(item, user = this.user) {
      return item.associacoes.some(function (el) {
        return el.id == user.associado;
      });
    },
    checkValorCategoria(valor, user = this.user) {
      return valor.categorias.some(function (el) {
        return el.id == user.categoria;
      });
    },
    getEndereco() {

      axios.get('https://viacep.com.br/ws/' + this.user.cep + '/json/').then(response => {
        if (response.data.erro) {
          this.$notify({
            type: "danger",
            message: "CEP não encontrado.",
          });
        } else {
          const data = response.data;
          this.user.bairro = data.bairro;
          this.user.cidade = data.localidade;
          this.user.uf = data.uf;
          this.user.endereco = data.logradouro;
        }
      })
    },

    setFile(event) {
      this.arquivo = event.target.files[0];
    },

    submitFile() {
      if (!this.boolean) {
        this.$notify({
          type: "danger",
          message: "Você precisa aceitar os termos e condições.",
        });
        return;
      }
      this.loading = true;

      let recaptcha = this.$recaptcha();
      recaptcha
        .then((token) => {

          this.errors = {};

          let user = this.user;
          user.token = token;

          let formData = helper.getFormDataTrue(user);

          if (this.arquivo)
            formData.append('arquivo', this.arquivo);

          CadastroService.createProjeto(formData).then(response => {
            this.success = true;

            this.$notify({
              type: "success",
              message: "Recebemos seu projeto com sucesso!",
            })

          }).catch(error => {
            if (error.response.data == "Captcha Inválido") {
              this.$notify({
                type: "danger",
                message: "Oops, ocorreu um erro com o ReCaptcha!",
              })
            } else {
              this.$notify({
                type: "danger",
                message: "Oops, verifique todos os campos!",
              })
              this.errors = error.response.data.errors;
            }

          }).finally(() => {
            this.loading = false;
          })
        });
    },


  },
};
</script>