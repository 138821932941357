<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-12" v-if="congresso.valores.length > 0">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <p style="margin: 30px 0 10px 0; float: left; width: 100%;"><strong>Informações sobre as inscrições</strong></p>
          <p style="margin: 30px 0 10px 0; float: left; width: 100%;"><strong>Curso Pré Congresso:</strong></p>
          <p> 200 Primeiras inscrições no Congresso darão direito ao pré Congresso Gratuito
            /50 vagas para cada curso</p>
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Cursos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="curso in cursos">
                <td>{{ curso.name }}</td>
              </tr>
            </tbody>
          </table>
          <p style="margin: 30px 0 10px 0; float: left; width: 100%;"><strong>Congresso:</strong></p>
          <div class="table-responsive">
            <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                  <th scope="col"></th>
                  <th scope="col" colspan="2">Associado</th>
                  <th scope="col" colspan="2">Não Associado</th>
                  <!-- <th scope="col" colspan="1">Estudante e Terceiro Setor</th> -->
                </tr>
                
              </thead>
              <tbody>
                <tr class="main-tr">
                  <th scope="col">Data das Inscrições</th>
                  <th scope="col">Médico</th>
                  <th scope="col">Residentes,<br> Dentistas,<br> Fonoaudiólogos,<br> Psicólogos,<br> Assistentes
                    Sociais,<br> Enfermeiros,<br> Outros</th>

                  <th scope="col">Médico</th>
                  <th scope="col">Residentes,<br> Dentistas,<br> Fonoaudiólogos,<br> Psicólogos,<br> Assistentes
                    Sociais,<br> Enfermeiros,<br> Outros</th>
                  <!-- <th scope="col">Estudante</th> -->
                </tr>
                <tr v-for="(pagamento, index) in congresso_formated.pagamentos" :key="index">
                  <th scope="row">até {{ index | DDMMYYYY }}</th>
                  <td>{{ pagamento.medicos.valor_associado | R$ }}</td>
                  <td>{{ pagamento.outros.valor_associado | R$ }}</td>

                  <td>{{ pagamento.medicos.valor_normal | R$ }}</td>
                  <td>{{ pagamento.outros.valor_normal | R$ }}</td>
                  <!-- <td>{{ pagamento.estudantes.valor_normal | R$ }}</td> -->

                </tr>
                <tr>
                  <th>No Local</th>
                  <td>R$ 830,00</td>
                  <td>R$ 610,00</td>
                  <td>R$ 1200,00</td>
                  <td>R$ 1000,00</td>
                  <!-- <td>R$ 350,00</td> -->
                </tr>

              </tbody>
            </table>
          </div>
          <p style="margin: 30px 0 10px 0; float: left; width: 100%;"><strong>Estudantes e Terceiro Setor:</strong></p>
          <div class="table-responsive">
            <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                  <th scope="col">Data das Inscrições</th>
                  <th scope="col">Estudante</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pagamento, index) in congresso_formated.pagamentos" :key="index">
                  <th scope="row">até {{ index | DDMMYYYY }}</th>
                  <td>{{ pagamento.estudantes.valor_normal | R$ }}</td>
                </tr>
                <tr>
                  <th>No Local</th>
                  <td>R$ 350,00</td>
                </tr>

              </tbody>
            </table>
          </div>
          <br />
          <h2>Regras Gerais</h2>

          Informações: <a href="mailto:congressofissuras2023@gmail.com">congressofissuras2023@gmail.com</a> ou (81)
          99971-7529
          <br />
          <br />
          <strong>
            <h3>Formas de pagamento</h3>
            Transferência bancária para: <br />
            Dados bancários: BANCO: 290 (PagSeguro) <br />
            AGÊNCIA:0001 <br/>
            CONTA:25600048-0 <br />
            Dione Maria Viana do Vale CPF 143.514.204-72
          </strong> (Não mandar PIX no CPF) <br />

          Enviar o comprovante para <a
            href="mailto:inscricaofissura2023@gmail.com">inscricaofissura2023@gmail.com</a><br />
          Ou através do link de pagamento.<br />
          <strong>Atenção: PIX só será aceito se realizado atráves do link de pagamento PagSeguro(O link da página de
            pagamento também é enviado ao seu email).</strong>
          <br />
          <br />
          Os participantes que se inscreverem como sócios das associações parceiras do evento,
          deverão estar quites com a anuidade, sendo obrigatório o envio do comprovante de
          filiação atualizado. Caso contrário será cobrada a diferença posteriormente.
          <br />
          Os Acadêmicos, Residentes, deverão enviar documento comprobatório, para
          <a href="mailto:inscricaofissura2023@gmail.com">inscricaofissura2023@gmail.com</a>
          <br />
          <br />
          <h3>Política de Cancelamento de Inscrições</h3>
          <br />
          Caso você tenha efetivado sua inscrição e por algum motivo não possa comparecer ao
          Congresso, poderá solicitar o cancelamento da inscrição, por escrito, via e-mail
          <a href="mailto:congressofissuras2023@gmail.com">congressofissuras2023@gmail.com</a> ou (81) 99971-7529 até
          15/07/2023.<br /><br />
          Os reembolsos serão efetuados após o Congresso, respeitando o seguinte critério:
          <ul>
            <li> Inscrições canceladas até 30/06/2023 – valor correspondente a 70% do valor pago,
              descontados os valores relativos a taxas e impostos.
            </li>
            <li> Inscrições canceladas até 14/07/2023 – valor correspondente a 50% do valor pago,
              descontados os valores relativos a taxas e impostos.
            </li>
            <li> O cancelamento solicitado após 15/07/2023 não será reembolsado.
            </li>
          </ul>
          <h3>ATENÇÃO</h3>
          Somente o próprio congressista poderá solicitar, quando de direito, o reembolso da
          inscrição.<br />
          O reembolso, quando de direito, será efetuado somente na conta corrente do inscrito.
          A inscrição é intransferível. Caso não possa comparecer ao evento, o reembolso só será
          realizado obedecendo aos prazos determinados acima
          <br />
          <p style="margin: 30px 0 10px 0; float: left; width: 100%;"><strong>Inscrição:</strong></p>
          <form role="form" @submit.prevent="submitFile()">

            <div class="row">
              <div class="col-12">
                <base-select label="Inscrição para" required input-classes="form-control-alternative"
                  placeholder="Selecione uma opção" v-model="user.tipo_cadastro" :options="tipoCadastro"
                  :errors="errors.tipo_cadastro" @change="setCarrinho()" />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div v-if="user.tipo_cadastro == 'congresso_curso' || user.tipo_cadastro == 'curso'">
                  <base-multiselect label="Cursos" required input-classes="form-control-alternative" :data="cursos"
                    v-model="user.cursos_selecteds" :errors="errors.cursos_selecteds" @input="setCarrinho()" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Nome" required input-classes="form-control-alternative" placeholder="Nome"
                  v-model="user.name" :errors="errors.name">
                </base-input>

              </div>

              <div class="col-12 col-md-6">
                <base-select label="Sexo" required input-classes="form-control-alternative" placeholder="Selecione"
                  v-model="user.sexo" :options="sexo" :errors="errors.sexo" />

              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Nome para Crachá" required input-classes="form-control-alternative" placeholder="Nome"
                  v-model="user.name_cracha" :errors="errors.name_cracha">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input type="date" label="Data de Nascimento" required input-classes="form-control-alternative"
                  v-model="user.nascimento" :errors="errors.nascimento" />

              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="CPF" required input-classes="form-control-alternative" placeholder="000.000.000-00"
                  v-model="user.cpf" :errors="errors.cpf" v-mask="'###.###.###-##'">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input label="RG" required input-classes="form-control-alternative" placeholder="00.000.000-0"
                  v-model="user.rg" :errors="errors.rg">
                </base-input>

              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Especialidade" required input-classes="form-control-alternative"
                  placeholder="Especialidade" v-model="user.especialidade" :errors="errors.especialidade">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-select label="Categoria" required input-classes="form-control-alternative" placeholder="Selecione"
                  v-model="user.categoria" :options="categorias" :errors="errors.categoria" />

              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Instituição" required input-classes="form-control-alternative"
                  placeholder="Instituição que representa" v-model="user.instituicao" :errors="errors.instituicao">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input label="Telefone" input-classes="form-control-alternative" placeholder="(00) 0000-0000"
                  v-mask="user.telefone.length < 15 ? '(##) ####-####?' : '(##) #####-####'" v-model="user.telefone"
                  :errors="errors.telefone">
                </base-input>

              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Email" required input-classes="form-control-alternative" placeholder="Email"
                  v-model="user.email" :errors="errors.email">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input label="Celular" required input-classes="form-control-alternative"
                  placeholder="(00) 00000-0000" v-mask="'(##) #####-####'" v-model="user.celular"
                  :errors="errors.celular">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="CEP" required input-classes="form-control-alternative" placeholder="00000-000"
                  v-model="user.cep" :errors="errors.cep" masked="false" @change="getEndereco()" v-mask="'#####-###'">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-select label="UF" required input-classes="form-control-alternative" placeholder="Selecione"
                  v-model="user.uf" :options="ufs" :errors="errors.uf" />
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Cidade" required input-classes="form-control-alternative" placeholder="Cidade"
                  v-model="user.cidade" :errors="errors.cidade">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input label="Endereço" required input-classes="form-control-alternative" placeholder="Endereço"
                  v-model="user.endereco" :errors="errors.endereco">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-input label="Número" required input-classes="form-control-alternative" placeholder="000"
                  v-model="user.numero" :errors="errors.numero">
                </base-input>
              </div>

              <div class="col-12 col-md-6">
                <base-input label="Complemento" input-classes="form-control-alternative" placeholder="Complemento"
                  v-model="user.complemento" :errors="errors.complemento">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <base-select label="Associado de" required input-classes="form-control-alternative"
                  placeholder="Não sou associado" v-model="user.associado" :options="associacoes"
                  :errors="errors.associado" />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <base-input type="file" @change="setFile($event)"
                  label="Comprovante da sua associação ou entidade(ex.: foto do crachá, etc)" accept="image/*"
                  input-classes="form-control-alternative" placeholder="Complemento" v-model="user.carteirinha"
                  :errors="errors.arquivo">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <base-select label="Seguro Saúde" required input-classes="form-control-alternative"
                  placeholder="Selecione" v-model="user.seguro_saude" :options="opcao" :errors="errors.seguro_saude" />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <base-input :rules="{ required: { allowFalse: false } }" name="Privacy" Policy>
                  <base-checkbox v-model="boolean">
                    <span class="text-muted">Eu concordo com os
                      <a href="#!">Termos e condições</a></span>
                  </base-checkbox>
                </base-input>
              </div>
            </div>

            <div v-if="user.categoria">
              Total: {{ total | R$ }}
              <ul>

                <li v-for="item in carrinho" :key="item.id">
                  {{ item.name }} - <span v-if="itemValor(item) != 0">{{ itemValor(item) | R$ }}</span><span
                    v-else>Grátis</span>
                </li>

              </ul>
            </div>

            <div class="text-center">
              <base-button type="primary" native-type="submit" :disabled="loading" class="mb-4">Continuar para PagSeguro
                <i class="fa fa-spinner fa-spin" v-if="loading"></i></base-button>

            </div>

          </form>
        </div>
      </div>
    </div>
    <div v-else> <h2>As inscrições foram encerradas!</h2> </div>
    <success-modal :show="success" :url="url"></success-modal>

  </div>
</template>
<script>
import SuccessModal from "../../components/SuccessModal";

import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import CadastroService from "../../services/CadastroPublic";
import { helper } from "../../helper.js";
import axios from "axios";

export default {
  name: "register",
  components: {
    ValidationError,
    Password,
    SuccessModal
  },
  mixins: [formMixin],
  data() {
    return {
      boolean: false,
      carteirinha: null,
      user: {
        tipo_cadastro: null,
        cursos_selecteds: [],
        name: null,
        sexo: null,
        name_cracha: null,
        nascimento: null,
        cpf: null,
        rg: null,
        especialidade: null,
        categoria: null,
        instituicao: null,
        telefone: "",
        email: null,
        celular: null,
        cep: null,
        uf: null,
        cidade: null,
        endereco: null,
        numero: null,
        complemento: null,
        associado: null,
        carteirinha: null,
        seguro_saude: null
      },

      cursos: [],
      categorias: [],
      associacoes: [],
      errors: {},
      congresso: {},
      congresso_formated: [],
      carrinho: [],

      loading: false,
      url: "#",
      success: false,
    };
  },
  mounted() {
    CadastroService.newModel().then(response => {
      this.cursos = response.data.cursos;
      this.associacoes = response.data.associacoes;
      this.categorias = response.data.categorias;
      this.congresso = response.data.congresso
      this.congresso_formated = response.data.congresso_formated
    });
  },
  computed: {
    total() {
      let total = 0;
      this.carrinho.forEach(item => {
        total += Number(this.itemValor(item));
      });
      return total;
    },
    tipoCadastro() {
      return [
        { id: 'congresso', name: 'Congresso' },
        { id: 'congresso_curso', name: 'Congresso + Curso' },
        { id: 'curso', name: 'Curso' },
      ];
    },
    sexo() {
      return [
        { id: 'feminino', name: 'Feminino' },
        { id: 'masculino', name: 'Masculino' },
      ];
    },
    opcao() {
      return [
        { id: '1', name: 'Sim' },
        { id: '0', name: 'Não' },
      ];
    },
    ufs() {
      let estados = [];

      Object.keys(helper.estados).forEach(function (sigla, index) {
        estados.push({ 'id': sigla, 'name': helper.estados[sigla] });
      });

      return estados;
    }
  },
  methods: {
    verify(event) {
      console.log(event);
    },
    itemValor(item) {
      let isAssociado = this.checkAssociado(item);

      if (item.valores.length > 0) {
        let result = 0;
        item.valores.every(valor => {
          if (valor.categorias.length == 0) {
            if (isAssociado) {
              result = valor.valor_associado ?? valor.valor_normal;
              return false;
            }
            else {
              result = valor.valor_normal;
              return false;
            }
          }

          if (this.checkValorCategoria(valor)) {
            if (isAssociado) {
              result = valor.valor_associado ?? valor.valor_normal;
              return false;
            }
            else {
              result = valor.valor_normal;
              return false;
            }
          }
          return true;
        });

        return result;

      } else return 0;
    },
    setCarrinho() {
      let cart = [];
      if (this.user.tipo_cadastro == 'congresso') {
        cart.push(this.congresso);
      }
      else if (this.user.tipo_cadastro == 'congresso_curso') {
        cart.push(this.congresso);
        this.user.cursos_selecteds.forEach(item => {
          cart.push(item)
        })
      }
      else {
        this.user.cursos_selecteds.forEach(item => {
          cart.push(item)
        })
      }
      this.carrinho = cart;
    },
    checkAssociado(item, user = this.user) {
      return item.associacoes.some(function (el) {
        return el.id == user.associado;
      });
    },
    checkValorCategoria(valor, user = this.user) {
      return valor.categorias.some(function (el) {
        return el.id == user.categoria;
      });
    },
    getEndereco() {

      axios.get('https://viacep.com.br/ws/' + this.user.cep + '/json/').then(response => {
        if (response.data.erro) {
          this.$notify({
            type: "danger",
            message: "CEP não encontrado.",
          });
        } else {
          const data = response.data;
          this.user.bairro = data.bairro;
          this.user.cidade = data.localidade;
          this.user.uf = data.uf;
          this.user.endereco = data.logradouro;
        }
      })
    },

    setFile(event) {
      this.carteirinha = event.target.files[0];
    },

    submitFile() {
      if (!this.boolean) {
        this.$notify({
          type: "danger",
          message: "Você precisa aceitar os termos e condições.",
        });
        return;
      }
      this.loading = true;

      let recaptcha = this.$recaptcha();
      recaptcha
        .then((token) => {

          this.errors = {};

          let user = this.user;
          user.carrinho = this.carrinho;
          user.total = this.total;
          user.token = token;

          let formData = helper.getFormDataTrue(user);

          if (this.carteirinha)
            formData.append('arquivo', this.carteirinha);

          CadastroService.create(formData).then(response => {
            this.url = response.data;
            this.success = true;
          }).catch(error => {
            if (error.response.data == "Captcha Inválido") {
              this.$notify({
                type: "danger",
                message: "Oops, ocorreu um erro com o ReCaptcha!",
              })
            } else {
              this.$notify({
                type: "danger",
                message: "Oops, verifique todos os campos!",
              })
              this.errors = error.response.data.errors;
            }

          }).finally(() => {
            this.loading = false;
          })
        });
    },


  },
};
</script>

<style>
.table tbody td {
  border: black 0.5px solid;
}
.table tbody th {
  border: black 0.5px solid;
}

.table tbody .main-tr th{
  font-size: 16px;;
}

.table .thead-dark th {
  background-color: rgb(68, 114, 196);
  /* color: rgb(31, 56, 100); */
  border: black 0.5px solid;
  color:white;
  font-size: 16px;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgb(217, 226, 243);
}

</style>