<template>
    <modal :show='show' @close='close()' modal-classes="modal-lg">
        <template v-slot:header>
            Sucesso!
        </template>
        <template>
            <div class="corpo">
                Recebemos seus dados com sucesso! <br />
                <a :href="url">Clique aqui</a> para seguir para a tela de pagamento no PagSeguro, ou aguarde {{ countDown }}
                segundos. <br />
                <br />
                O link de pagamento também foi enviado para seu email.
            </div>
        </template>
    </modal>
</template>
<script>
export default {
    name: "success-modal",
    data() {
        return {
            countDown: 14
        };
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: "#"
        },
    },
    mounted() {
        // $("#excluirModal").modal("show");

        this.triggerHidden();
        this.countDownTimer()
    },
    methods: {
        triggerHidden: function () {
        },
        close(update) {
            if (!update) {
                update = false;
            }

            this.$emit("close", update);
        },

        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    if (this.show)
                        this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                window.location.href = this.url
            }
        }
    },
};
</script>
<style>
.corpo {
    text-align: center;
}
</style>
