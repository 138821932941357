import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = '/cadastro';
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class CadastroPublic {

    static newModel() {
        return axios.get(url + '/create');
    }

    static create(model) {
        return axios.post(
            url,
            model, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        );
    }

    static createProjeto(model) {
        return axios.post(
            url + '/projeto',
            model, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        );
    }

    static submit(model) {
        return abstract.submit(model, false); // qnd true é passado é para ser convertido em formData
    }

}